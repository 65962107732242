@import "../../_globalColor";

.dev-icons {
  padding-left: 0;
  list-style: none;
  font-size: 3rem;
  margin-bottom: 0px;
  margin-top: 0px;
  text-align: center;
}

.software-skill-inline {
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 20px;
}

.software-skill-inline > i {
  color: $subTitle;
}

.software-skill-inline > p {
  color: $subTitle;
  font-size: 10px;
}
.software-skill-inline > a > p {
  color: $subTitle;
  font-size: 12px;
}
.software-skill-inline > i:hover ~ p {
  color: $skillsColor;
}

.JavaScript > i:hover {
  color: yellow; /* Color for JavaScript icon */
}
.nodejs > i:hover {
  color: green; /* Color for Node icon */
}
.npm > i:hover {
  color: red; /* Color for NPM icon */
}
.PostgreSQL > i:hover {
  color: grey; /* Color for PSQL icon */
}

// If Google class has dark-background class, then change the color of the icon to black otherwise icon should be white on hover
.Google > i:hover {
  color: black; /* Color for Google icon */
}
.Google.dark-background > i:hover {
  color: white;
}
.python > i:hover {
  color: blue; /* Color for Python icon */
}
.docker > i:hover {
  color: blue; /* Color for Docker icon */
}
